/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker-wrapper {
  width: 100%;
}

.slick-arrow {
  color: #000;
  z-index: 1;
  width: auto !important;
  height: auto !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
}

.slick-next,
[dir='rtl'] .slick-prev {
  right: 8px !important;
  left: auto !important;
}

.slick-prev,
[dir='rtl'] .slick-next {
  left: 8px !important;
  right: auto !important;
}

.slick-dots {
  bottom: 4px !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
  color: #fff !important;
}

select {
  padding-bottom: 8px !important;
}

[dir='rtl'] .chakra-select__icon-wrapper {
  right: auto !important;
  left: 4px !important;
}

[dir='rtl'] .chakra-select {
  -webkit-padding-end: 1.8em;
  padding-inline-end: 1.8em;
}

/* Table responsive */

.mobile-optimized-table {
  border-collapse: collapse;
}

@media screen and (max-width: 767px) {
  table.mobile-optimized-table {
    word-wrap: break-word;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  table.mobile-optimized-table thead {
    display: none;
  }

  table.mobile-optimized-table tbody {
    display: block;
  }

  .mobile-optimized-table tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #ddd;
    padding: 16px;
    margin-bottom: 10px;
    border-radius: 16px;
    gap: 12px 4px;
  }

  table.mobile-optimized-table td,
  table.mobile-optimized-table th {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    border: none;
    color: #465373;
  }
  table.mobile-optimized-table td:nth-child(1) {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    color: #5b6b93;
    font-size: 1rem;
    font-weight: 500;
  }
}

@media screen and (max-width: 405px) {
  table.mobile-optimized-table td {
    width: 100%;
  }
}

.intercom-app,
.intercom-lightweight-app {
  position: relative !important;
  z-index: 1399 !important;
}

.pac-container {
  z-index: 1401 !important;
}

[dir='rtl'] .chakra-numberinput > div {
  right: auto;
  left: 0;
}
